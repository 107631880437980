
import React from 'react';
import Global from '../layout/global';
import Blog from '../components/Blog';
import { graphql } from 'gatsby';
import './blog.scss';

/**
 * Renders the blog page with Blog and BlogItem components.
 */
const BlogPages = ({ data }) => {
    const posts = data.allMdx.edges.map(
        (edge) => {
            return {
                title: edge.node.frontmatter.title,
                subtitle: edge.node.frontmatter.subtitle,
                author: edge.node.frontmatter.author,
                slug: edge.node.fields.slug
            };
        }
    );
    return (
    <Global title="Blog" page="blog" background="/img/background/homepage3.jpg">
            <Blog headline="Copperhead Blog" posts={ posts } />
        </Global>
    );
};


export const pageQuery = graphql`
    query {
        allMdx(
            filter: {
                frontmatter: { layout: { eq: "post" } }
            }
            sort: {
                fields: [frontmatter___date],
                order: DESC
            }
        ) {
            edges {
                node {
                    fields {
                        slug
                    }
                    frontmatter {
                        title
                        subtitle
                        author
                    }
                }
            }
        }
    }
`;

export default BlogPages;
